const Media = {
  bindEvents: () => {
    // toggle content
    document.querySelectorAll('.click-toggle').forEach((item) => {
      item.addEventListener('click', () => {
        item.querySelector('.click-content').classList.toggle('hidden');
        item.querySelector('.click-arrow').classList.toggle('toggle-arrow');
      });
    });

    if (document.getElementById('play-button')) {
      document.querySelectorAll('#play-button').forEach((item) => {
        const iframe = item.nextElementSibling;

        item.addEventListener('click', () => {
          item.classList.add('hidden');
          let iframeUrl = iframe.getAttribute('src');
          iframeUrl = iframeUrl + '&autoplay=1';
          iframe.setAttribute('src', iframeUrl);
        });

        let height = iframe.clientHeight.toString() + 'px';
        item.style.minHeight = height;
      });
    }

    window.onresize = () => {
      if (document.getElementById('play-button')) {
        document.querySelectorAll('#play-button').forEach((item) => {
          const iframe = item.nextElementSibling;
          let height = iframe.clientHeight.toString() + 'px';
          item.style.minHeight = height;

          item.addEventListener('click', () => {
            item.classList.add('hidden');
            let iframeUrl = iframe.getAttribute('src');
            iframeUrl = iframeUrl + '&autoplay=1';
            iframe.setAttribute('src', iframeUrl);
          });

          height = iframe.clientHeight.toString() + 'px';
          item.style.minHeight = height;
        });
      }
    };
  },
};

export default Media;
