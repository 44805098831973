class Navigation {
  constructor(
    body,
    menuOpen,
    mobileNavContainer,
    mobileNavToggle,
    subNavToggle,
    backBtn,
    searchToggleDesktop,
    searchToggleMobile
  ) {
    this.body = body;
    this.menuOpen = menuOpen;
    this.mobileNavContainer = mobileNavContainer;
    this.mobileNavToggle = mobileNavToggle;
    this.subNavToggle = subNavToggle;
    this.backBtn = backBtn;
    this.searchToggleDesktop = searchToggleDesktop;
    this.searchToggleMobile = searchToggleMobile;
  }

  init() {
    this.setup(document);
  }

  setup(scope) {
    this.attachEvents(scope);
  }

  attachEvents(scope) {
    const getFocusableElements = () => {
      const currentLevel = this.mobileNavContainer.getAttribute('data-current-level');

      if (currentLevel === '1') {
        const mergedItems = [];
        const levelOneItems = document.querySelectorAll('.mobile-nav__item--l1');

        levelOneItems.forEach((element) => {
          const subelements = element.querySelectorAll('button, [href]');
          subelements.forEach((subelement) => {
            mergedItems.push(subelement);
          });
        });

        return mergedItems;
      }

      return document
        .querySelector('.mobile-nav__list--current')
        .querySelectorAll('button, [href]');
    };

    const setFocus = () => {
      const focusableElements = getFocusableElements();
      focusableElements[0].focus();
      focusableElements[focusableElements.length - 1].addEventListener('focusout', () => {
        focusableElements[0].focus();
      });
    };

    // Main Hamburger Button
    if (this.mobileNavToggle) {
      this.mobileNavToggle.addEventListener('click', () => {
        this.doToggleMenu();
        setFocus();
      });
    }

    if (this.mobileNavContainer) {
      // Navigation through submenus
      this.mobileNavContainer.addEventListener('click', (e) => {
        if (e.target !== e.currentTarget) {
          if (
            e.target.tagName === 'BUTTON' ||
            e.target.tagName === 'H4' ||
            e.target.tagName === 'H5' ||
            e.target.tagName === 'A'
          ) {
            return;
          }
          this.openMenuLevel(e);
          setFocus();
        } else {
          // Click away to close menu
          this.doToggleMenu();
        }
      });
    }

    // Escape key closes menu
    scope.addEventListener('keypress', (e) => {
      if (this.menuOpen && e.key === 'Escape') this.doToggleMenu();
    });

    // Submenu open buttons
    this.subNavToggle.forEach((button) => {
      button.addEventListener('click', (e) => {
        this.doOpenSubMenu(e);
        setFocus();
      });
    });

    // Submenu back buttons
    this.backBtn.forEach((button) => {
      button.addEventListener('click', (e) => {
        this.doCloseSubMenu(e);
        setFocus();
      });
    });
  }

  doToggleMenu() {
    this.mobileNavContainer.classList.add('mobile-nav--animated');
    if (this.menuOpen) {
      this.openMenuLevel({
        target: document.querySelector('.mobile-nav__footer--level1'),
      });
    }

    this.mobileNavContainer.classList.toggle('mobile-nav--open');
    this.body.classList.toggle('scroll-lock');

    if (!this.menuOpen) {
      this.mobileNavContainer
        .querySelectorAll('[data-menu-list]')[0]
        .classList.add('mobile-nav__list--current');
    }

    this.menuOpen = !this.menuOpen;

    if (!this.menuOpen) {
      this.mobileNavToggle.classList.remove('is-active');
      if (
        document.getElementById('popout-modal') &&
        document.getElementById('popout-modal').classList.contains('popout-open')
      ) {
        document.getElementById('popout-modal').classList.remove('hidden');
      }
    } else {
      this.mobileNavToggle.classList.add('is-active');
      if (
        document.getElementById('popout-modal') &&
        document.getElementById('popout-modal').classList.contains('popout-open')
      ) {
        document.getElementById('popout-modal').classList.add('hidden');
      }
    }
    if (this.mobileNavToggle.getAttribute('data-current-level') > 2) {
      this.mobileNavToggle.classList.add('reversed');
      this.searchToggleDesktop.classList.add('reversed');
      this.searchToggleMobile.classList.add('reversed');
    } else {
      this.mobileNavToggle.classList.remove('reversed');
      this.searchToggleDesktop.classList.add('reversed');
      this.searchToggleMobile.classList.add('reversed');
    }

    this.mobileNavToggle.setAttribute('aria-expanded', this.menuOpen);
  }

  doOpenSubMenu(e) {
    const currentLists = document.querySelectorAll('.mobile-nav__list--current');

    currentLists.forEach((currentList) => {
      currentList.classList.remove('mobile-nav__list--current');
    });

    e.currentTarget.parentNode
      .querySelector('[data-menu-list]')
      .classList.add('mobile-nav__list--open', 'mobile-nav__list--current');
    e.currentTarget.setAttribute('aria-expanded', true);

    const currentLevel = this.mobileNavContainer.getAttribute('data-current-level');

    this.mobileNavContainer.setAttribute('data-current-level', Number(currentLevel) + 1);

    if (this.mobileNavContainer.getAttribute('data-current-level') > 2) {
      this.mobileNavToggle.classList.add('reversed');
      this.searchToggleDesktop.classList.add('reversed');
      this.searchToggleMobile.classList.add('reversed');
    }
  }

  doCloseSubMenu(e) {
    const targetMenu = document
      .querySelector('.mobile-nav__list--current')
      .parentNode.closest('.mobile-nav__list--open, .mobile-nav__inner [data-menu-list]');

    document
      .querySelector('.mobile-nav__list--current')
      .classList.remove('mobile-nav__list--current');

    targetMenu.classList.add('mobile-nav__list--current');

    targetMenu.querySelector('[aria-expanded=true]').setAttribute('aria-expanded', false);

    e.currentTarget.closest('[data-menu-list]').classList.remove('mobile-nav__list--open');

    const currentLevel = this.mobileNavContainer.getAttribute('data-current-level');

    this.mobileNavContainer.setAttribute('data-current-level', Number(currentLevel) - 1);

    if (this.mobileNavContainer.getAttribute('data-current-level') <= 2) {
      this.mobileNavToggle.classList.remove('reversed');
      this.searchToggleDesktop.classList.remove('reversed');
      this.searchToggleMobile.classList.remove('reversed');
    }
  }

  openMenuLevel(e) {
    const clickTargetSelector = `.${e.target.className.replaceAll(' ', '.')}`;

    const clickTarget = document.querySelector(clickTargetSelector);

    let targetMenu = clickTarget.closest('.mobile-nav__list--nested');

    if (targetMenu == null) {
      targetMenu = clickTarget
        .closest('.mobile-nav__inner')
        .querySelector(':scope > [data-menu-list]');
    }

    if (targetMenu && !targetMenu.classList.contains('mobile-nav__list--current')) {
      targetMenu.querySelectorAll('[data-menu-list]').forEach((list) => {
        list.classList.remove('mobile-nav__list--open', 'mobile-nav__list--current');
      });

      targetMenu.querySelectorAll('[aria-expanded=true]').forEach((list) => {
        list.setAttribute('aria-expanded', false);
      });

      targetMenu.classList.add('mobile-nav__list--current');

      this.mobileNavContainer.setAttribute(
        'data-current-level',
        document.querySelectorAll('.mobile-nav__list--open').length + 1
      );
    }
  }
}

export default Navigation;
