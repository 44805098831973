const Search = {
  init: () => {
    var form = document.getElementById('global-search-form');
    document.querySelectorAll('.change-field').forEach((fields) => {
      fields.addEventListener('change', (field) => {
        form.submit();
      });
    });

    // onchange of category clear the select field data
    document.querySelectorAll('.category-field').forEach((fields) => {
      fields.addEventListener('change', (field) => {
        // clear select fields
        document.querySelectorAll('.category-clear').forEach((field) => {
          field.value = '';
        });

        // submit form
        form.submit();
      });
    });

    // incrementally add pages to div when read more is clicked
    if (document.getElementById('view-more-button')) {
      document.getElementById('view-more-button').addEventListener('click', () => {
        var searchResults = document.getElementById('search-results');
        var pageStart = parseInt(document.getElementById('page-start').value);
        var pageCounter = parseInt(document.getElementById('page-counter').value);
        // add page results count to page start when adding the next lot of results in
        var start = pageStart + pageCounter;
        let path = window.location.href.split('?')[0];

        // add page start to results for next results to be added
        document.getElementById('page-start').value = start;

        // fetch data
        fetch(path + 'home/loadMoreResponse' + window.location.search + '&start=' + start)
          .then(function (response) {
            return response.text();
          })
          .then(function (html) {
            if (html.length == 1) {
              document.getElementById('view-more-button').classList.add('hidden');
            }
            searchResults.innerHTML = searchResults.innerHTML + html;
          })
          .catch(function (err) {
            console.warn('Something went wrong.', err);
          });
      });
    }

    // search modal
    if (
      document.getElementById('search-toggle--desktop') ||
      document.getElementById('search-toggle--mobile')
    ) {
      const toggleSearchBtns = document.querySelectorAll('.search-toggle');
      const searchModal = document.getElementById('search-modal');
      const layout = document.getElementById('layout');
      const input = searchModal.querySelector('input[name="Keywords"]');

      toggleSearchBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
          layout.classList.toggle('hidden');
          btn.classList.toggle('search-closed');
          searchModal.classList.toggle('search-modal-open');
          searchModal.setAttribute(
            'aria-hidden',
            searchModal.getAttribute('aria-hidden') === 'true' ? 'false' : 'true'
          );
          input?.focus();

          layout.setAttribute(
            'aria-hidden',
            layout.getAttribute('aria-hidden') === 'false' ? 'true' : 'false'
          );
        });
      });
    }
  },
};

export default Search;
