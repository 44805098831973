import '@styles/index.scss';

import Header from '@components/Header';
import Links from '@components/Links';
import SiteAlert from '@components/SiteAlert';
import ScrollTo from '@components/ScrollTo';
import Breadcrumbs from '@components/Breadcrumbs';
import Navigation from '@components/Navigation';
import PopoutModal from '@components/PopoutModal';
import Search from '@components/Search';
import StoryCarousel from '@components/StoryCarousel';
import Media from '@components/Media';
import Accordion from '@components/Accordion';
import Tables from '@components/Tables';
import ImageCarousel from '@components/ImageCarousel';
import Tabs from '@components/Tabs';
import StaffTile from '@components/StaffTile';
import '@imgix/js-core';
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

document.addEventListener('DOMContentLoaded', () => {
  Header.bindEvents();
  const nav = new Navigation(
    document.querySelector('body'),
    false,
    document.querySelector('[data-mobile-nav]'),
    document.getElementById('mobile-nav-toggle'),
    document.querySelectorAll('[data-toggle-sub]'),
    document.querySelectorAll('[data-toggle-back]'),
    document.querySelector('#search-toggle--desktop'),
    document.querySelector('#search-toggle--mobile')
  );
  nav.init();
  if (document.getElementById('elemental-area')) {
    Links.bindEvents();
  }
  SiteAlert.init();
  ScrollTo.init();
  Breadcrumbs.init();
  PopoutModal.init();
  Search.init();
  StoryCarousel.initCarousel();
  StoryCarousel.mobileCarousel();
  Media.bindEvents();
  Accordion.init();
  Tables.init();
  StaffTile.init();
  ImageCarousel.initCarousel();
  document.querySelector('.tablinks') ? Tabs.init() : null;
});
