const PopoutModal = {
  queryString: window.location.search,
  popout: document.querySelector('#popout-modal'),
  openBtnsDesktop: document.querySelector('#open-popout--desktop'),
  openBtnsMobile: document.querySelector('#open-popout--mobile'),
  formHolder: document.querySelector('.popout-form-holder'),
  navHeight: document.querySelector('#nav') ? document.querySelector('#nav').clientHeight : null,
  popoutOffset: `-${(document.querySelector('#popout-modal')
    ? document.querySelector('#popout-modal').clientHeight + 32
    : 0
  ).toString()}px`,
  activeButton: null,

  init: () => {
    if (PopoutModal.popout || PopoutModal.isPopoutFormSubmission()) {
      PopoutModal.setup();
    }
  },

  setup: () => {
    PopoutModal.popout.style.right = PopoutModal.popoutOffset;
    if (window.getComputedStyle(PopoutModal.openBtnsDesktop).display === 'block') {
      PopoutModal.activeButton = PopoutModal.openBtnsDesktop;
    } else {
      PopoutModal.activeButton = PopoutModal.openBtnsMobile;
    }

    PopoutModal.attachEvents();
  },

  attachEvents: () => {
    window.addEventListener('resize', () => {
      if (window.getComputedStyle(PopoutModal.openBtnsDesktop).display === 'block') {
        PopoutModal.activeButton = PopoutModal.openBtnsDesktop;
      } else {
        PopoutModal.activeButton = PopoutModal.openBtnsMobile;
      }

      if (!PopoutModal.popout.classList.contains('popout-open')) {
        PopoutModal.activeButton.firstElementChild.classList.add('hidden');
      } else {
        PopoutModal.activeButton.firstElementChild.classList.remove('hidden');
      }

      PopoutModal.formHolder.style.height = `${(
        PopoutModal.popout.clientHeight - PopoutModal.activeButton.clientHeight
      ).toString()}px`;
      PopoutModal.formHolder.style.top = `${PopoutModal.activeButton.clientHeight}px`;

      PopoutModal.popoutOffset = `-${(PopoutModal.popout.clientHeight + 32).toString()}px`;

      PopoutModal.popout.style.right = PopoutModal.popoutOffset;
    });

    PopoutModal.openBtnsDesktop.addEventListener('click', (e) => {
      const btn = PopoutModal.openBtnsDesktop;
      e.preventDefault();
      PopoutModal.clickEvent(btn);
    });

    PopoutModal.openBtnsMobile.addEventListener('click', (e) => {
      const btn = PopoutModal.openBtnsMobile;
      e.preventDefault();
      PopoutModal.clickEvent(btn);
    });
  },

  clickEvent: (btn) => {
    document.body.classList.toggle('overflow-x-hidden');
    if (PopoutModal.navHeight) {
      PopoutModal.popout.style.height = `calc(100% - ${PopoutModal.navHeight}px)`;
    }

    if (PopoutModal.popout.classList.contains('popout-closed')) {
      PopoutModal.updateClosedPopoutClasses(btn);
      PopoutModal.formHolder.style.height = `${(
        PopoutModal.popout.clientHeight - btn.clientHeight
      ).toString()}px`;
      PopoutModal.formHolder.style.top = `${btn.clientHeight}px`;
      PopoutModal.formHolder.style.overflowY = 'scroll';
    } else {
      PopoutModal.updateOpenedPopoutClasses(btn);
    }

    if (PopoutModal.isPopoutFormSubmission()) {
      PopoutModal.updateSubmittedPopup();
    }
  },

  updateClosedPopoutClasses: (btn) => {
    PopoutModal.popout.classList.add('popout-open');
    PopoutModal.popout.classList.remove('popout-closed', 'md:rotate-270', 'lg:rotate-270');
    PopoutModal.popout.style.right = '0';
    btn.firstElementChild.classList.remove('hidden');
    PopoutModal.formHolder.classList.remove('hidden');
  },

  updateOpenedPopoutClasses: (btn) => {
    PopoutModal.popout.classList.remove('popout-open');

    PopoutModal.popout.classList.add('popout-closed', 'md:rotate-270', 'lg:rotate-270');

    btn.firstElementChild.classList.add('hidden');
    PopoutModal.formHolder.classList.add('hidden');
    PopoutModal.popout.style.top = '40%';
    PopoutModal.popout.style.right = PopoutModal.popoutOffset;
    PopoutModal.popout.style.height = 'auto';
  },

  updateSubmittedPopup: () => {
    if (document.querySelector('.page-title')) {
      document.querySelector('.page-title').remove();
    }

    PopoutModal.formHolder.style.height = `${(
      PopoutModal.formHolder.clientHeight + 60
    ).toString()}px`;
    PopoutModal.formHolder.style.overflowY = 'scroll';

    if (document.querySelector('.close-modal')) {
      const closeModalBtn = document.querySelector('.close-modal');

      if (document.getElementById('enquiry-form')) {
        document.getElementById('enquiry-form').scrollIntoView({
          behavior: 'smooth',
        });
      }

      closeModalBtn.addEventListener('click', () => {
        PopoutModal.updateOpenedPopoutClasses(PopoutModal.activeButton);
      });
    }
  },

  isPopoutFormSubmission: () => PopoutModal.queryString.includes('popout'),
};

export default PopoutModal;
