const StaffTile = {
    init: () => {
        const isOpen = "is-open";
        const body = document.body;

        const openButtons = document.querySelectorAll("[data-open]");
        const closeButtons = document.querySelectorAll("[data-close]");

        function trapFocus(element) {
            var focusableEls = element.querySelectorAll(
                'a[href]:not([disabled]), button:not([disabled])'
            );
            var firstFocusableEl = focusableEls[0];
            var lastFocusableEl = focusableEls[focusableEls.length - 1];
            var KEYCODE_TAB = 9;

            firstFocusableEl.focus();
            element.addEventListener('keydown', function (e) {
                var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) /* shift + tab */ {
                    if (document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    }
                } else /* tab */ {
                    if (document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                }
            });
        }

        const SetHidden = (ele) => {
            ele.setAttribute('tabindex', '-1');
            ele.setAttribute('aria-hidden', true);
            ele.classList.remove(isOpen);
            body.classList.remove('no-scroll');
        };

        // open a modal
        for (const el of openButtons) {
            el.addEventListener("click", function (e) {
                e.preventDefault();
                const modal = document.getElementById(this.dataset.open);
                modal.classList.add(isOpen);
                modal.setAttribute("tabindex", 0);
                modal.setAttribute("aria-hidden", false);
                trapFocus(modal)
                body.classList.add("no-scroll");
            });
        }

        // close by clicking on the custom [data-close] element which is located inside the modal:

        for (const el of closeButtons) {
            el.addEventListener("click", function (e) {
                e.preventDefault();
                var modal = document.querySelector('[class*="is-open"]');
                SetHidden(modal);
            });
        }

        // close by clicking on everything outside of the modal:

        document.addEventListener('click', (e) => {
            e = e || window.event;
            const target = e.target;
            if (target.classList.contains(isOpen)) {
                e.preventDefault();
                SetHidden(target);
            }
        });

        // close by pressing ESC key

        document.addEventListener('keyup', (e) => {
            const modal = document.querySelector('.modal-container.is-open');
            if (e.key === 'Escape' && modal) {
                SetHidden(modal);
            }
        });
    },
};

export default StaffTile;
