import Cookies from 'js-cookie';

const SiteAlert = {
  init: () => {
    const alertBanner = document.getElementById('site-alert');
    const search = document.getElementById('search-modal');
    const bannerCookie = Cookies.get('AlertDismissed');

    let w = window.innerWidth;
    document.documentElement.clientWidth || document.body.clientWidth;

    if (bannerCookie && alertBanner) {
      alertBanner.classList.add('hidden');
    }

    if (alertBanner && !bannerCookie) {
      let bannerOffset = `${alertBanner.clientHeight.toString()}px`;
      const nav = document.querySelector('nav:not(.mobile-nav)');
      const mobileNav = document.querySelector('nav.mobile-nav');
      const layout = document.getElementById('layout');
      layout.style.marginTop = w <= 768 ? '15rem' : '12rem';
      search.style.marginTop = w <= 768 ? '15rem' : '8rem';
      nav.style.top = bannerOffset;
      mobileNav.style.top = bannerOffset;

      window.addEventListener('resize', () => {
        w = window.innerWidth;
        document.documentElement.clientWidth || document.body.clientWidth;
        layout.style.marginTop = w <= 768 ? '15rem' : '12rem';
        search.style.marginTop = w <= 768 ? '15rem' : '8rem';
        layout.style.marginTop = w <= 767 && '12rem';
        bannerOffset = `${alertBanner.clientHeight.toString()}px`;
        nav.style.top = bannerOffset;
        mobileNav.style.top = bannerOffset;
      });

      if (document.querySelector('.dismiss-alert')) {
        const closeBtn = document.querySelector('.dismiss-alert');

        closeBtn.addEventListener('click', () => {
          Cookies.set('AlertDismissed', 'true');
          alertBanner.classList.add('hidden');
          layout.style.marginTop = '5rem';
          search.style.marginTop = '8rem';
          nav.style.top = '0';
          mobileNav.style.top = '0';
        });
      }
    }
  },
};

export default SiteAlert;
